import { schema } from 'normalizr'
import { contactSchema } from '~/store/contacts/contacts.schema'

export const venteSchema = new schema.Entity('ventes', {
  bien: new schema.Entity('biens'),
  collaborateur: new schema.Entity('collaborateurs'),
  contacts: [contactSchema],
  estimationRendezVous: new schema.Entity('rendezVous'),
})

export const ventesListSchema = new schema.Entity('ventes', {
  bien: new schema.Entity('biens'),
  collaborateur: new schema.Entity('collaborateurs'),
})