import { normalizePhone } from '@apimmo/front/utils/strings'
import {
  map,
  path as pathRamda,
  pipe,
  prepend,
  prop,
  propEq,
  reject,
  sortBy,
} from 'ramda'
import { useDispatch } from 'react-redux'
import { generatePath, useHistory, useParams } from 'react-router'
import { useUnmount } from 'react-use'
import { Files } from '~/components/common/FileUploader/FileUploader'
import { paths as routes } from '~/routes/routes'
import { selectConnectedUserId } from '~/store/auth/auth.selectors'
import { addOrUpdateCollaborateurs } from '~/store/collaborateurs/collaborateurs.slice'
import { addOrUpdateNotaires } from '~/store/notaires/notaires.slice'
import { useSelector } from '~/store/utils'
import { get, getFirst } from '~/utils/get'
import { useAppToasts, useAsync, useAsyncFn } from '~/utils/hooks'
import { Document } from '../documents/documents.types'
import {
  addContactRelation,
  addPhoneToContact,
  createContact,
  createInvitation,
  deleteContact,
  deleteContactRelation,
  fetchCollaborateurContacts,
  fetchContactById,
  fetchContactByPhone,
  fetchContactsDeleted,
  fetchContactsList,
  hardDeleteContacts,
  updateContact,
  updateContactPatch,
  updateDocumentsOfContact,
} from './contacts.api'
import {
  selectAchatLinkedContact,
  selectContactById,
} from './contacts.selectors'
import { addOrUpdateContacts, removeContactById } from './contacts.slice'
import {
  AddContactRelationPayload,
  AddPhoneToContactPayload,
  Contact,
  ContactCreationPayload,
  ContactUpdatePatchPayload,
  ContactUpdatePayload,
  ContactsFetchParams,
  CreateInvitationPayload,
} from './contacts.types'
import { PaginationQueryParam } from '../searchParams/searchParams.types'
import { SearchFilters } from '~/utils/forms/contactSearchUtils'

export const useSelectContactFromParams = () => {
  const { contactId } = useParams<{ contactId: string }>()
  const contact = useSelector(selectContactById(contactId))
  return contact
}

export const useSelectAchatLinkedContactFromParams = () => {
  const { contactId, achatId } = useParams<{
    contactId: string
    achatId: string
  }>()
  const contact = useSelector(selectAchatLinkedContact(contactId, achatId))
  return contact
}

export const useFindContactByPhone = (telephone: string | null) => {
  const dispatch = useDispatch()
  const { push } = useHistory()

  return useAsync(async () => {
    if (telephone) {
      const e164Phone = normalizePhone(
        telephone.startsWith('0') ? telephone : `+${telephone}`,
      )
      const { contacts = {} } = await fetchContactByPhone(e164Phone)

      const contact = getFirst(contacts)
      dispatch(addOrUpdateContacts(contacts))

      push(generatePath(routes.showContact, { contactId: contact.id }))
    }
  }, [telephone])
}

export const useFetchCollaborateurContacts = () =>
  useAsyncFn(fetchCollaborateurContacts, [])

export const useFetchContactsSupprimes = () =>
  useAsyncFn(fetchContactsDeleted, [])

export const useFetchContacFromParams = () => {
  const { contactId } = useParams<{ contactId: string }>()
  const dispatch = useDispatch()

  return useAsync(async () => {
    const {
      contacts = {},
      collaborateurs = {},
      notaires = {},
    } = await fetchContactById(contactId)
    dispatch(addOrUpdateNotaires(notaires))
    dispatch(addOrUpdateCollaborateurs(collaborateurs))
    dispatch(addOrUpdateContacts(contacts))
  }, [contactId])
}

export type FetchAllContactParams = {
  pagination: PaginationQueryParam | null
  searchFilters: SearchFilters | null
}

export const useFetchAllContacts = () => {
  const dispatch = useDispatch()

  return useAsyncFn(
    async ({ pagination, searchFilters }: FetchAllContactParams) => {
      const params: ContactsFetchParams = {
        ...pagination,
        ...searchFilters,
      }

      const { contacts: { contacts = {} }, meta } = await fetchContactsList(params)

      dispatch(addOrUpdateContacts(contacts))

      return { contacts, meta }
    },
    [],
  )
}

export const useUpdateContact = (contactId: string) => {
  const dispatch = useDispatch()
  const { successToast } = useAppToasts()

  const [, doCall] = useAsyncFn(
    async (payload: ContactUpdatePayload) => {
      const { contacts = {} } = await updateContact(contactId, payload)
      dispatch(addOrUpdateContacts(contacts))

      successToast('Le profil a été mis à jour.')
    },
    [contactId],
  )

  return doCall
}

export const useUpdateContactPatch = (contactId: string) => {
  const dispatch = useDispatch()
  const { successToast } = useAppToasts()

  const [, doCall] = useAsyncFn(
    async (payload: ContactUpdatePatchPayload) => {
      const { contacts = {} } = await updateContactPatch(contactId, payload)
      dispatch(addOrUpdateContacts(contacts))

      successToast('Le profil a été mis à jour.')
    },
    [contactId],
  )

  return doCall
}

export const useCreateContact = () => {
  const dispatch = useDispatch()
  const creatorId = useSelector(selectConnectedUserId)

  return useAsyncFn(async (payload: ContactCreationPayload) => {
    const { contacts = {}, collaborateurs = {} } = await createContact({
      ...payload,
      creatorId,
    })

    dispatch(addOrUpdateContacts(contacts))
    dispatch(addOrUpdateCollaborateurs(collaborateurs))

    return getFirst(contacts)
  }, [])
}

export const useRemoveContactOnUnmount = (contact?: Contact) => {
  const dispatch = useDispatch()

  useUnmount(() => {
    if (contact && contact.id && !contact.nomComplet) {
      dispatch(removeContactById(contact.id))
    }
  })
}

export const useAddPhoneToContact = () => {
  const dispatch = useDispatch()
  const { successToast } = useAppToasts()

  const [, doCall] = useAsyncFn(
    async (contactId: string, payload: AddPhoneToContactPayload) => {
      const { contacts = {} } = await addPhoneToContact(contactId, payload)
      dispatch(addOrUpdateContacts(contacts))

      successToast('Le numéro de téléphone a été associé au contact.')
    },
    [],
  )

  return doCall
}

export const useAddContactRelation = () => {
  const dispatch = useDispatch()

  const [, doCall] = useAsyncFn(
    async (contactId: string, payload: AddContactRelationPayload) => {
      const { contacts = {} } = await addContactRelation(contactId, payload)
      dispatch(addOrUpdateContacts(contacts))
    },
    [],
  )

  return doCall
}

export const useDeleteContactRelation = () => {
  const dispatch = useDispatch()

  const [, doCall] = useAsyncFn(
    async (contactId: string, payload: AddContactRelationPayload) => {
      const { contacts = {} } = await deleteContactRelation(contactId, payload)
      dispatch(addOrUpdateContacts(contacts))
    },
    [],
  )

  return doCall
}

export const useDeleteContact = (contactId: string) => {
  const dispatch = useDispatch()
  const { successToast } = useAppToasts()

  const [, doCall] = useAsyncFn(async () => {
    await deleteContact(contactId)
    dispatch(removeContactById(contactId))
    successToast('Le contact a bien été supprimé.')
  }, [contactId])

  return doCall
}
export const useCreateInvitation = () => {
  const dispatch = useDispatch()
  const { successToast } = useAppToasts()

  const [, doCall] = useAsyncFn(
    async (payload: CreateInvitationPayload) => {
      await createInvitation(payload)

      if (payload.contactId) {
        const {
          contacts = {},
        } = await fetchContactById(payload.contactId)
        dispatch(addOrUpdateContacts(contacts))
      }

      successToast('Le contact a été invité.')
    },
    [],
  )

  return doCall
}

export const useHardDeleteContacts = () => {
  const { successToast, errorToast } = useAppToasts()

  return useAsyncFn(async (contactIds: string[]) => {
    try {
      await hardDeleteContacts({ contactIds })

      successToast('Les contacts ont bien été supprimés.')
    } catch {
      errorToast(
        "Une erreur s'est produite pendant la suppression des contacts.",
      )
    }
  })
}

export const useUploadContactDocuments = (contact: Contact) => {
  const { errorToast, successToast } = useAppToasts()
  const dispatch = useDispatch()
  const documents = get(contact, 'documents', [])

  const [, doCall] = useAsyncFn(
    async (files: Files, docType: string) => {
      try {
        const { nom, paths, id } = await updateDocumentsOfContact(contact.id, {
          nom: docType,
          paths: map(pathRamda(['meta', 'path']), files) as string[],
        })
        const newDocuments: Document[] = pipe(
          reject<Document, 'array'>(propEq('nom', nom)),
          prepend({ nom, paths, id }),
          sortBy(prop('nom')),
        )(documents)

        dispatch(
          addOrUpdateContacts({
            [contact.id]: {
              ...contact,
              documents: newDocuments,
            },
          }),
        )
        successToast('Les documents ont été mis à jour.')
      } catch {
        errorToast(
          "Une erreur s'est produite, les documents ont été stockés, mais pas enregistrés par Papiris.",
        )
      }
    },
    [contact, documents],
  )

  return doCall
}
