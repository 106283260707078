import React, { lazy, Suspense } from 'react'
import { Route, Switch } from 'react-router'
import { Panel } from '~/components/common/Panel/Panel'
import { paths } from '~/routes/routes'
import { useFetchContacFromParams, useRemoveContactOnUnmount, useSelectContactFromParams } from '~/store/contacts/contacts.hooks'

const ContactTransactions = lazy(
  () => import('./ContactTransactions/ContactTransactions'),
)

const EditContact = lazy(() => import('./EditContact/EditContact'))

export const SocietePages = () => {
  const { loading } = useFetchContacFromParams()
  const contact = useSelectContactFromParams()

  useRemoveContactOnUnmount(contact)

  if (!contact || !contact.id) {
    if (loading) {
      return null
    }

    return (
      <Panel>La société recherchée n‘existe pas.</Panel>
    )
  }

  return (
    <Suspense fallback={null}>
      <Switch>
        <Route path={paths.editSociete} render={() => <EditContact />} />
        <Route
          path={paths.societeTransactions}
          render={() => <ContactTransactions />}
          exact
        />
        {/* <Route path={paths.newVente} render={() => <NewVente />} exact /> */}
        {/* <Route path={paths.ventePages} render={() => <VentePages />} /> */}
        {/* <Route
          path={paths.planificationDecouverteRendezVous}
          render={() => <PlanificationDecouverteRendezVous />}
        /> */}
        {/* <Route path={paths.newAchat} render={() => <NewAchat />} exact /> */}
        {/* <Route path={paths.achatPages} render={() => <AchatPages />} /> */}
      </Switch>
    </Suspense>
  )
}

export default SocietePages
