export enum SignatureType {
  RENDU_ESTIMATION = 'rendu_estimation',
  MANDAT = 'mandat',
  MANDAT_AVENANT = 'mandat_avenant',
  LIA = 'lia',
  OTHER = 'other',
}

export const SIGNATURE_TYPE_LABEL: Record<SignatureType, string> = {
  [SignatureType.RENDU_ESTIMATION]: 'Rendu d‘estimation',
  [SignatureType.MANDAT]: 'Mandat',
  [SignatureType.MANDAT_AVENANT]: 'Avenant au mandat',
  [SignatureType.LIA]: 'Lettre d\'intention d\'achat',
}

export enum ListSignatureSort {
  CREATED_AT_DESC = 'createdAt,desc',
  CREATED_AT_ASC = 'createdAt,asc',
}

export const LIST_SIGNATURE_SORT_LABEL: Record<ListSignatureSort, string> = {
  [ListSignatureSort.CREATED_AT_DESC]: 'Du plus récent',
  [ListSignatureSort.CREATED_AT_ASC]: 'Du plus ancien',
}

export enum SignatureSignerMode {
  EMAIL = 'otp_email',
  SMS = 'otp_sms',
}

export function isSignatureSignerMode(mode: string): mode is SignatureSignerMode {
  return mode === SignatureSignerMode.EMAIL || mode === SignatureSignerMode.SMS
}

export enum SignatureSignerRole {
  ACQUEREUR = 'acquereur',
  VENDEUR = 'vendeur',
  CONSEILLER = 'conseiller',
  MANDATAIRE = 'mandataire',
}

export const SignatureSize = {
  width: 100,
  height: 44,
}

export const SignatureSizeOther = {
  width: 200,
  height: 100,
}