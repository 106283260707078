import { borderRadius, spacings } from '@apimmo/front/style/theme'
import styled from '@emotion/styled'
import { colors } from '~/style/theme'
import { StyledBlock } from '../StyledGenericComponents/StyledBlock'

export const Panel = styled(StyledBlock)`
  padding: ${spacings.small};
  background-color: ${colors.grey50};
  border: 1px solid ${colors.grey100};
  border-radius: ${borderRadius};
`
