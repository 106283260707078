import { Spacing, spacings } from '@apimmo/front/style/theme'
import styled from '@emotion/styled'
import React, { ReactNode } from 'react'

const StyledBlockComponent = styled.div<{ gutterTop?: Spacing }>`
  &:not(:last-of-type) {
    margin-bottom: ${({ gutterTop }) =>
      gutterTop ? spacings[gutterTop] : spacings.small};
  }
`

export type StyledBlockProps = {
  gutterTop?: Spacing
}

export const StyledBlock = ({
  children,
  ...otherProps
}: StyledBlockProps & { children: ReactNode }) => (
  <StyledBlockComponent {...otherProps}>{children}</StyledBlockComponent>
)
