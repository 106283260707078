import { curry } from 'ramda'
import { compact } from 'ramda-adjunct'
import { selectOffreById } from '~/store/offres/offres.selectors'
import { RootState } from '~/store/types'
import { selectVenteById } from '~/store/ventes/ventes.selectors'
import { Vente } from '~/store/ventes/ventes.types'
import { selectVisiteById } from '~/store/visites/visites.selectors'
import { BienVente } from './biens.types'
import { getAllBienPhotos, isBienFull } from './biens.utils'

export const selectBienById = curry(
  (bienId: string, { biens }: RootState) => biens.entities[bienId],
)

export const selectBienByVenteId = curry(
  (venteId: string, state: RootState) => {
    const vente = selectVenteById(venteId, state)

    return selectBienById(vente?.bien || '', state)
  },
)

export const selectBienByOffreId = curry(
  (offreId: string, state: RootState) => {
    const offre = selectOffreById(offreId, state)

    return selectBienByVenteId(offre?.vente || '', state)
  },
)

export const selectVentesWithBiens = curry(
  (venteIds: string[], state: RootState): BienVente[] => {
    const ventes: Vente[] = compact(
      venteIds.map((venteId) => selectVenteById(venteId, state)),
    )

    const biens = state.biens.entities

    return compact(
      ventes.map(
        ({
          reference,
          statut,
          prixAgence,
          id: venteId,
          bien: bienId,
          hasLockedActiveMandat,
        }) => {
          const bien = biens[bienId]
          if (!bien) return null

          return {
            venteId,
            reference,
            prixAgence,
            statut,
            ville: bien.ville,
            latitude: bien.latitude,
            longitude: bien.longitude,
            chambresCount: bien.chambresCount,
            surfaceTerrain: bien.surfaceTerrain,
            type: bien.type,
            bienId: bien.id,
            hasLockedActiveMandat,
          }
        },
      ),
    )
  },
)

export const selectBienByVisiteId = curry(
  (visiteId: string, state: RootState) => {
    const visite = selectVisiteById(visiteId, state)
    if (!visite) return null

    const bien = selectBienByVenteId(visite.vente, state)
    return bien
  },
)

export const selectAllPhotosOfBien = curry(
  (bienId: string, state: RootState) => {
    const bien = selectBienById(bienId, state)

    if (!bien || !isBienFull(bien)) return []

    return getAllBienPhotos(bien)
  },
)

export const selectListPieces = curry(
  () => state.ventes.entities[venteId],
)
